table.zurhai {
  min-width: 800px;
}
table.zurhai tr,
table.zurhai td {
  font-size: 14px;
  line-height: 18px;
  height: 20px;
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  padding: 0;
  width: 165px;

  &.name-team {
    height: 55px;
  }

  &.onoo-cell {
    height: 80px;
  }

  &.name-of-ue {
    width: 40px;

    td {
      width: 40px;
    }
  }

  &.column-names {
    width: 100px;

    td {
      width: 100px;
    }
  }
}
table.zurhai tr > td {
  width: 165px;
}

table.zurhai,
table.zurhai tr > td,
table.zurhai td > tr > td {
  border: 1px solid #282c34;
}

.border-btm {
  border: none !important;
  border-bottom: 1px solid #282c34 !important;
}

.border-none {
  border: none !important;
}

.height-61 {
  height: 61px;
}

.rotate-line {
  transform: rotate(-90deg);

  &.onoo-cell {
    width: 80px;
  }
}

.zurhai-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name-of-ue {
  transform: rotate(-90deg);
}

.height-6 {
  border: 1px solid #000;
  cursor: pointer;
}
